import { Trans } from '@lingui/react';
import clsx from 'clsx';
import type { StoreMoney } from '../../../types.d/storeMoney';
import { useFormatMoney } from '../../../utils/hooks/useFormatMoney';

import styles from './ProductPrice.module.scss';

export type PriceType =
  | 'unit'
  | 'pre-discount'
  | 'discounted'
  | 'compare'
  | 'bundle';

export const ProductPrice = ({
  price,
  isLoading = true,
  type,
  className,
  viewModeOnlyMobile,
}: {
  price: StoreMoney;
  type: PriceType;
  isLoading: boolean;
  className?: string;
  viewModeOnlyMobile?: boolean;
}) => {
  const localisedPrice = useFormatMoney(price);
  const mobileViewClassExtension = viewModeOnlyMobile ? 'MobileOnly' : '';
  const returnPriceClass = () => {
    const className = `productPrice--${type}${mobileViewClassExtension}`;

    return styles[className];
  };

  return (
    <p
      className={clsx(returnPriceClass(), 'product-price')}
      data-testid="productPrice"
    >
      <span className={className}>
        {isLoading && (
          <span className="is-loading">
            <Trans id="accessibility.loading" message="Loading..." />
          </span>
        )}

        {!isLoading &&
          (() => {
            switch (type) {
              case 'unit':
              case 'discounted':
              case 'pre-discount':
              case 'compare':
                return localisedPrice;
              case 'bundle':
                return (
                  <Trans
                    id="price.price_value"
                    message="({localisedPrice} value)"
                    values={{ localisedPrice }}
                  />
                );
            }
          })()}
      </span>
    </p>
  );
};
